<template>
  <router-link
    :to="{ path: '/' }"
    class="text-decoration-none"
  >
    <v-btn
      :ripple="false"
      :height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
      depressed
      :small="$vuetify.breakpoint.smAndDown"
      class="black-btn mr-5 mr-md-10">
      <span
				class="vue-editor font-weight-bold px-md-7 px-8 white--text"
				v-html="trans('404_page_go_home_btn')"
			/>
    </v-btn>
  </router-link>
</template>

<script>
export default {
name: 'GoHome'
}
</script>

<style lang="scss" scoped>
.black-btn {
  border-radius: 30px !important;
  background: var(--v-black-base) !important;
  color: white !important;
  transition: all 0.4s;
  &:hover {
    background: rgba($color: #000000, $alpha: 0.9) !important;
  }
}
</style>
