import GoHome from '@/components/btns/GoHome'

export default {
  name: 'Page404',
  components: {
    GoHome
  },
	metaInfo: {
		title: '1tn',
		titleTemplate: null
	}
}
